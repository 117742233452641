import React from "react";
import styled from "styled-components";

import { maxMedia } from "../../styles/utils/media-queries";
import { border, colors } from "../../styles/global";

const VideoWrapperBackground = styled.div`
  min-height: 320px;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: ${border.radius}px;
  border: 1px solid ${colors.borderSecondary};
`;

export const VideoContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;

  img {
    object-fit: cover;
    min-width: 105%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
  }

  video {
    object-fit: cover;
    min-width: 105%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100%;

    ${maxMedia.mobile`
      width: auto;
      left: 0;
      top: 0;
      transform: translate(0, 0);
    `}
  }
`;

export function VideoWrapper(props) {
  return (
    <VideoWrapperBackground {...props}>
      <VideoContainer>{props.children}</VideoContainer>
    </VideoWrapperBackground>
  );
}

const videoTypes = ["mp4", "ogg", "webm"];

function getSourceType(url) {
  const fileExtension = url.split(".").pop();
  if (videoTypes.includes(fileExtension)) {
    return `video/${fileExtension}`;
  }
  return null;
}

export function Video(props) {
  const container = React.createRef(null);
  const [initialized, setInitialized] = React.useState(false);
  const sourceType = getSourceType(props.src);

  // the following is required due to react not being able to set the muted property of a video
  // https://github.com/facebook/react/issues/10389
  React.useEffect(() => {
    if (!container || !container.current || initialized) return;
    const video = document.createElement("video");

    video.autoplay = true;
    video.loop = true;
    video.muted = true; // actually mute the video
    if (props.placeholder) {
      video.setAttribute("poster", props.placeholder);
    }
    video.setAttribute("muted", ""); // force the mute attribute on the video
    video.setAttribute("preload", "");
    video.setAttribute("playsinline", ""); // fixes autoplay in webkit (ie. mobile safari)
    video.setAttribute("autoplay", "");
    video.removeAttribute("controls"); // hide controls on iphone

    const source = document.createElement("source");
    source.src = props.src;
    source.type = sourceType;
    video.appendChild(source);

    container.current.appendChild(video);
    setInitialized(true);
  }, [container, props.src, props.placeholder, sourceType, initialized]);

  return <div ref={container} />;
}
