import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import { colors } from "../../styles/global";

const Background = styled.div`
  display: flex;
  :not(:first-of-type) {
    margin-top: 2rem;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 1.4rem;
  color: ${colors.ipsred};
  margin-right: 1.4rem;
  margin-top: 6px;
`;

const Text = styled.p`
  color: ${colors.textSecondary};
  font-size: 1.2rem;
  line-height: 2rem;
`;

export function CheckMarkItem(props) {
  return (
    <Background>
      <Icon icon={faCheck} />
      <Text>{props.text}</Text>
    </Background>
  );
}

export const CheckMarkList = styled.div`
  padding: 0;
`;
