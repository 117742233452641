import styled from "styled-components";
import { maxMedia } from "../../styles/utils/media-queries";

interface WrapProps {
  inverse?: boolean;
}

const Wrap = styled.div<WrapProps>`
  display: flex;
  flex-wrap: wrap;

  > * {
    flex: 1;
  }

  > *:not(:last-child) {
    margin-right: 4rem;
  }

  ${maxMedia.tablet`

    > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 2rem;
      margin-top: 2rem;
    }

    flex-direction: ${(props) => (props.inverse ? "column-reverse" : "column")};
  `}
`;

export default Wrap;
