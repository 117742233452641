import React from "react";
import styled from "styled-components";

import { Container } from "./Container";
import { ButtonPrimary } from "./Button";
import { narrow } from "./Section";
import { colors } from "../../styles/global";
import { maxMedia } from "../../styles/utils/media-queries";

export const HeroTextBox = styled.div`
  z-index: 10;
`;

export const Hero = styled.div`
  display: flex;
  height: 32rem;
  font-size: 1.8rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  position: relative;

  ${ButtonPrimary} {
    background: #9302b9;
  }

  ${Container} {
    display: flex;
    height: 100%;
  }

  ${HeroTextBox} {
    align-self: center;
  }
`;

export const ImageBackground = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-image: url(${(props) => props.url});

  ${maxMedia.tablet`
    height: auto;
    padding: 2rem;
  `}
`;

export const VideoContainer = styled.div`
  height: calc(100% + 10px);
  width: calc(100% + 10px);
  overflow: hidden;
  position: absolute;

  video {
    background: transparent;
    z-index: -1;
    object-fit: cover;
    min-width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    filter: blur(3px);

    ${maxMedia.mobile`
      filter: blur(1px);
      width: auto;
      height: 100%;
      left: -4px;
      top: -4px;
      transform: translate(0, 0);
    `}
  }
`;

export const HeroTitle = styled.h1`
  font-size: 3.7rem;
  line-height: 5rem;
  margin-bottom: 4rem;
  color: ${(props) => (props.inverted ? colors.textInverted : colors.text)};
`;

export const HeroCaption = styled.h3`
  font-size: 1.6rem;
  line-height: 2.3rem;
  font-weight: 300;
  margin-bottom: 2rem;
  color: ${(props) => (props.inverted ? colors.textInverted : colors.text)};
`;

export const HeroText = styled.p`
  color: ${colors.textSecondary};
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-weight: 400;
  ${(props) => props.narrow && narrow};
`;

export const HeroTextBoxWhite = styled(HeroTextBox)`
  border-radius: 4px;
  padding: 2rem 4rem 2rem 2rem;
  background: white;
  color: ${colors.text};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`;

export const HeroOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  opacity: 0;
  z-index: 1;
`;

export function VideoBackground(props) {
  return (
    <VideoContainer style={props.style}>
      <HeroOverlay />
      {props.children}
    </VideoContainer>
  );
}
