import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled, { keyframes } from "styled-components";
import { colors } from "../../styles/global";
import { maxMedia } from "../../styles/utils/media-queries";

const pulsate = keyframes`
  from {
    filter: brightness(.5);
  }

  50% {
    filter: brightness(1);
  }

  to {
    filter: brightness(.5);
  }
`;

export const VideoBackground = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${colors.backgroundDark};
  animation: ${pulsate} 2s linear infinite;

  i,
  svg {
    color: ${colors.textInverted};
    font-size: 4rem;
    align-self: center;
  }
`;

export function LoadingBackground() {
  return (
    <VideoBackground>
      <FontAwesomeIcon icon={faYoutube} />
    </VideoBackground>
  );
}

const IframeContainer = styled.div`
  position: relative;

  iframe {
    height: 100%;
    width: 100%;
  }
`;

export function YoutubeEmbed(props) {
  const [isLoading, setLoading] = React.useState(true);
  return (
    <IframeContainer>
      <iframe
        onLoad={() => setLoading(false)}
        title="video"
        src={props.url}
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
      />
      {isLoading && <LoadingBackground />}
    </IframeContainer>
  );
}

export const VideoList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;

  /* fix for ie 11 */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;
    flex-wrap: wrap;
    > * {
      flex: 1;
    }
  }
  /* --- */

  ${maxMedia.tablet`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${maxMedia.mobile`
    grid-template-columns: repeat(1, 1fr);
  `}

  > * {
    height: 320px;
    min-width: 0;
  }
`;
